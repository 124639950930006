import React from "react";
import classes from "./Arrow.module.css";
import cx from "classnames";
import { ReactComponent as NavArrowIcon } from "assets/navArrow.svg";

export default function Arrow(props) {
  const { color = "", className = "" } = props;
  return (
    <NavArrowIcon className={cx(classes.arrow, className)} style={{ color }} />
  );
}
