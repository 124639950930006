import React, { useContext, useEffect, useMemo, useState } from "react";
import classes from "./Footer1.module.css";
import { ReactComponent as NavIcon } from "assets/nav.svg";
import QrcodeUrl from "assets/qrcode.png";
import { useDevice } from "../../hook/useDevice";
import { CatalogContext } from "../../context";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import events from "@/event";
import Arrow from "../Arrow";
import { navs } from "./const";
import { useCallback } from "react";

function Footer1(props) {
  const nav = useNavigate();
  const { cataList, cataList1, cataList2 } = props;
  const solution = cataList.find((c) =>c.name ==="解决方案中心")
  console.info("solution===>", solution)
  return (
    <div className={classes.footer}>
      <Nav />

      <div className={classes.content}>
        <div className={classes.contract}>
          <div className={classes.text1}>联系电话</div>
          <div className={classes.text3}>010-62017490</div>
          <div className={classes.text3}>18610881234</div>
          <div className={classes.text3}>
            或者致电<a style={{ color: "#2C60F5" }} href="/contact">产品销售</a>
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.title}>解决方案</div>
          <div className={classes.divider}></div>
          {(solution?.categories ?? []).map((d, index) => (
            <div
              key={index}
              className={classes.text4}
              onClick={() => {
                nav(`/solution/${solution.id}?categoryId=${d.id}`);
                events.emit("scroll");
              }}
            >
              {d.name}
            </div>
          ))}
        </div>
        
        <div className={classes.item}>
          <div className={classes.title}>产品技术</div>
          <div className={classes.divider}></div>
          {(cataList1 ?? []).map((d, index) => (
            <div
              key={index}
              className={classes.text4}
              onClick={() => {
                nav(`/product/${d.id}`);
                events.emit("scroll");
              }}
            >
              {d.name}
            </div>
          ))}
        </div>
        <div className={classes.item}>
          <div className={classes.title}>联系我们</div>
          <div className={classes.divider}></div>
          {(cataList2 ?? []).map((d, index) => (
            <div
              key={index}
              className={classes.text4}
              onClick={() => {
                nav("/about");
                setTimeout(() => {
                  const dom = document.getElementById(`aboutme_${d.name}`);
                  dom.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }, 500);
              }}
            >
              {d.name}
            </div>
          ))}
          <a href="/history" className={classes.text4}>
            发展历程
          </a>
        </div>
        <div className={classes.qrcode}>
          <img className={classes.qrcode1} src={QrcodeUrl} />
          <div>关注 BIEM</div>
          <div>随时了解动态</div>
        </div>
      </div>
      <div className={classes.links}>
        <div>
          Copyright ©️ 2022 - 北京市电加工研究所有限公司 京ICP备2021023252号-1 京公安网备 11010802040973
        </div>
        <div
          className={classes.link}
          onClick={() => {
            nav("/privacy");
            events.emit("scroll");
          }}
        >
          <div>隐私政策</div>
          <div>｜</div>
          <div>用户协议</div>
        </div>
      </div>
    </div>
  );
}

function Nav() {
  const nav = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [arr, setArr] = useState([]);

  const onCatalog = useCallback((n) => {
    setName(n);
  }, []);
  const onCatalogObj = useCallback((n) => {
    console.log(33333, n);
    setArr(n);
  }, []);

  const cur = useMemo(() => {
    return navs.find((n) => location.pathname.includes(n.path));
  }, [navs, location.pathname]);

  useEffect(() => {
    setName("");
  }, [cur]);

  useEffect(() => {
    events.on("catalog", onCatalog);
    events.on("catalogObj", onCatalogObj);

    return () => {
      events.off("catalog", onCatalog);
      events.off("catalogObj", onCatalogObj);
    };
  }, [onCatalog]);

  return (
    <div className={classes.navs}>
      <NavIcon className={classes.navIcon} onClick={() => nav("/")} />
      <Arrow className={classes.arrowIcon} color="#CFCFCF" />
      {cur && (
        <span
          style={{
            cursor: cur.path && !cur.noLink ? "pointer" : "",
            whiteSpace: "nowrap",
          }}
          onClick={() => !cur.noLink && cur.path && nav(cur.path)}
        >
          {cur.name}
        </span>
      )}
      {arr.map((a) => (
        <>
          <Arrow className={classes.arrowIcon} color="#CFCFCF" />
          <span
            style={{ cursor: a.path ? "pointer" : "", whiteSpace: "nowrap" }}
            onClick={() => a.path && nav(a.path)}
          >
            {a.name}
          </span>
        </>
      ))}
      {name && (
        <>
          <Arrow color="#CFCFCF" />
          <span style={{ whiteSpace: "nowrap" }}>{name}</span>
        </>
      )}
    </div>
  );
}

export default Footer1;
