import { useEffect, useMemo, useState } from "react";


export function useDevice() {
  const [isH5, setIsH5] = useState(false)


  useEffect(() => {

    var docEl = document.documentElement;
    if(docEl?.clientWidth > 750) {
      setIsH5(false)
    } else {
      setIsH5(true)
    }
  }, [])

  return useMemo(() => {
    return {
      isH5
    }
  }, [isH5])
}