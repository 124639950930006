const navs = [{
  path: '/about',
  name: '走进我们',
}, {
  path: '/news',
  name: '新闻资讯',
}, {
  path: '/solution',
  name: '解决方案',
  noLink: true
}, {
  path: '/product',
  name: '产品技术',
  noLink: true
}, {
  path: '/cooperation',
  name: '合作交流',
}, {
  path: '/jobs',
  name: '人才招聘',
}, {
  path: '/honor',
  name: '荣誉资质',
}, {
  path: '/contact',
  name: '联系我们'
}]

export {
  navs
}